<template>
  <div>
    <el-card>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{padding: '6px 0'}"
        :headerCellStyle="{background:'rgba(51, 55, 68)',color:'#fff'}"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
        <template v-slot:operation="slotProps">
          <el-tooltip class="item" effect="dark" :content="$t('btnTip').edit" placement="top">
            <el-button
              @click="handleClick('edit',slotProps.callback.row)"
              icon="el-icon-edit-outline"
              type="primary"
              size="mini"
            ></el-button>
          </el-tooltip>
        </template>
      </my-table>
    </el-card>
  </div>
</template>

<script>
import { mixin_list } from "@/mixins";
import myTable from '@/components/table'
import { serviceTermList } from '@/api/index';
export default {
    name:'setting',
  mixins: [ mixin_list(serviceTermList)],
  components: {
    myTable
  },
  data () {
    return {
      columns: [
        {
          label: 'ID',
          prop: 'id',
          width:200
        }, {
          label: this.$t('serviceTerm.title'),
          prop: 'title',
        }, 
        {
          label: this.$t('operation'),
          prop: '',
          slot: 'operation'
        }],
    }
  },
  methods: {
    handleClick (value, item) {
      switch (value) {
        case 'edit':
          this.$router.push(`/serviceTerm/edit?id=${item.id}`)
          break;
      }
    },
  }
}
</script>